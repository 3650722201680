@font-face {
  font-family: 'Satoshi-Light';
  src: url('/public/fonts/Satoshi-Light.otf');
}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('/public/fonts/Satoshi-Regular.otf');
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('/public/fonts/Satoshi-Medium.otf');
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('/public/fonts/Satoshi-Bold.otf');
}

@font-face {
  font-family: 'Satoshi-Black';
  src: url('/public/fonts/Satoshi-Black.otf');
}

@import "primeicons/primeicons.css";

:root {
  --main-blue: #284277;
  --main-grey: #9B9FA3;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Satoshi-Regular';
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Satoshi-Bold';
  color: var(--main-blue);
}

#root {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.negritas {
  font-family: 'Satoshi-Bold';
}

main {
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.nav-menu a,
button {
  /* font-family: 'Satoshi-Bold'; */
  font-weight: initial !important;
}

.btn-primary {
  background-color: var(--main-blue);
}

.btn-eliminar span {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z'/%3E%3C/svg%3E") no-repeat left center;
  background-size: 1.25rem;
  padding-left: 1.5rem;
}


.btn-icon {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5rem;
}

.badge {
  font-size: 0.875rem;
}

.input-group .input-group-prepend .input-group-text {
  border-radius: 0.375rem 0 0 0.375rem;
}
.input-group .input-group-append .input-group-text {
  border-radius: 0 0.375rem 0.375rem 0 ;
}

@media (width < 1400px) {
  .badge-inscripto {
    font-size: 0.75rem;
  }
}