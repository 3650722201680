.cont-list-products ul {
    width: 100%;
    padding: 0;
    
    display: grid;
    justify-content: space-between;
    /* grid-template-rows: repeat(auto-fit, 1fr); */
    /* grid-template-columns: repeat(auto-fit, 300px); */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    row-gap: 1rem;
}


.cont-list-products li {
    /* padding: 0rem; */
    margin-bottom: 0rem;
    border: none;
    list-style-type: none;
    max-width: 300px;
}

@media screen and (max-width: 768px) {
    
    .cont-list-products ul {
        display: flex;
        flex-direction: column;
    }
    .cont-list-products li {
        max-width: 100%;
    }
    
}