.card-class-preview-cont .card-title {
    font-size: 1.25rem;
}
.card-class-preview-cont .card-description {
    height: 100px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
}