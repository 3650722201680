.cont-purchase {
  background-color: #ebebeb;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  overflow: hidden;
}

.cont-purchase .purchase-date {
  border-bottom: 1px solid lightgray;
  margin: 0;
  font-weight: 700;
}

.cont-purchase .purchase-date .purchase-date-body {
  background-color: rgba(255, 255, 255, 0.65);
  padding: 0.5rem 2.5rem;

}

.cont-purchase .purchase-details {
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: row;
}

.cont-purchase .purchase-summary {
  display: flex;
  flex-direction: column;
  max-width: 30%;
  width: 100%;
  padding: 0 2rem;
}

.cont-purchase .purchase-summary p {
  margin-bottom: 0rem;
}

.cont-purchase .purchase-items {
  flex-grow: 1;
  max-width: 70%;
}

.cont-purchase .purchase-items ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
}

.cont-purchase .purchase-items li {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
}

.cont-purchase .purchase-items li h3 {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .cont-purchase .purchase-details {
    flex-direction: column;
  }

  .cont-purchase .purchase-summary {
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .cont-purchase .purchase-items {
    max-width: 100%;
    padding: 0 2rem;
  }
}