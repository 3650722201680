.card-view-profile-container .user-card {
    padding: 2rem;
    gap: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

}

.card-view-profile-container .edit-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    overflow: visible;
}

.card-view-profile-container .avatar-img {
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    height: 150px;
}