.auth-cont {
  padding-inline: 1.5rem;
}

.auth-cont .auth-card {
  min-height: 100%;
  border: 1px solid lightgray;
  border-radius: 0.5rem;
  overflow: hidden;
}

.auth-cont .auth-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  align-items: center;
  padding: 1rem;
}

.auth-cont .auth-card-img {
  min-height: 150px;
  padding: 0;
  background: url('/public/assets/clases.jpeg') no-repeat center center;
  background-size: cover;
}


.auth-cont .brand {
  display: block;
  height: 5rem;
  width: 100%;
  text-decoration: none;
  color: inherit;
  background: url('/public/assets/logo-cgres-completo-azul.svg') no-repeat center center;
  background-size: contain;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .auth-cont .auth-card-body {
    padding: 3rem;
  }
  
}