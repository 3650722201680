.cart-product-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 5px;
}

.cart-product-cont img {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.cart-product-cont a {
  text-decoration: none;
}

.cart-product-cont:hover .card-title {
  color: var(--bs-link-color);
}

.cart-product-cont:hover .card-price {
  color: var(--bs-link-color);
}

.cart-product-cont .card-title {
  font-size: 1.5rem;
  color: var(--main-blue);
  font-weight: 700;
  transition: all 0.15s ease-in-out;
}

.cart-product-cont .card-price {
  font-size: 1.25rem;
  transition: all 0.15s ease-in-out;
}

.cart-product-cont .counter-cantidad {
  z-index: 3;
  gap: 1rem;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .cart-product-cont .card-title {
    font-size: 1rem;
  }
  .cart-product-cont .card-price {
    font-size: 1rem;
  }
}