.cont-view-product .product-image {
  aspect-ratio: 1;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.cont-view-product .card-price {
  font-size: 2rem;
  margin-top: 2rem;
}

.cont-view-product .btn {
  width: fit-content;
}