.footer {
    width: 100%;
    background-color: #252422;
    min-height: 100px;
    display: grid;
    place-content: center;
    z-index: 3;
    /* position: fixed;
    bottom: 0; */
}

.footer {
    color: white;
}

/* .footer ul {
    padding: 0;
    margin: 0;
} */
.footer ul {
    padding: 0;
    margin: 0;
}

.footer .navigation>ul {
    width: 100%;

    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer li {
    list-style-type: none;
}

.footer a {
    color: white;
    text-decoration: none;
}