.cont-admin-cursos ul {
    padding: 0;
    margin: 1rem 0;
}
.cont-admin-cursos ul li {
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    list-style-type: none;
}

.sidebar {
    background-color: #f8f9fa; /* Color de fondo */
}

.sidebar a {
    color: #495057; /* Color del texto */
}

/* Estilo para el enlace activo */
.sidebar a.active {
    font-weight: bold;
    color: #007bff; /* Color del enlace activo */
}

