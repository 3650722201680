.navbar-base {
  display: flex;
  color: white;
  box-shadow: 0 8px 20px -15px rgba(0, 0, 0, 0.5);
  padding: 0.5rem 2rem;
  background: var(--main-blue);
}

.navbar-base-offcanvas {
  display: flex;
  color: white;
  box-shadow: 0 8px 20px -15px rgba(0, 0, 0, 0.5);
  background: var(--main-blue);
}

.brand {
  text-decoration: none;
  color: inherit;
  background: url('/public/assets/logo-cgres-completo-blanco.svg') no-repeat left center;
  background-size: contain;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.navbar-collapse {
  justify-content: flex-end;
}

.nav-menu {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  align-items: center;
}

.navbar-base .navbar {
  cursor: default;
}

.nav-menu a {
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.nav-menu a:not(:hover) {
  color: inherit;
}

.nav-menu a:not(.submenu-item):hover {
  color: var(--bs-gray-400);
}

.panel-ddown>.dropdown-toggle {
  padding: 0 !important;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.btn-profile {
  cursor: pointer;
}

.profile-links {
  position: absolute;
  background-color: white;
  color: black;
  top: 3.15rem;
  padding: 0.5rem 0;
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: 0.375rem;
  min-width: 10rem;
  z-index: 100;
}

.profile-links a,
.profile-links button {
  padding: 0.25rem 1rem;
}

.profile-links a:hover,
.profile-links button:hover {
  background-color: #e9ecef;
  border-radius: 0;
}

@media screen and (max-width: 991.90px) {

  .nav-menu a:not(.submenu-item):hover {
    color: var(--bs-link-hover-color);
  }

  .nav-menu {
    margin-top: 1rem;
    gap: 1rem;
  }

  .logout {
    text-align: left;
  }
}