.cont-edit-profile-card {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* text-align: center; */

}

.cont-edit-profile-card .avatar-img {
    object-fit: cover;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    height: 150px;
}

.cont-edit-profile-card form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.cont-edit-profile-card .preview-image {
    width: 50%;
}