.open-class-banner-cont {
    padding: 2rem;
    background-color: #284277;
    background-image: linear-gradient(315deg, #284277 0%, #4263aa 51%, #284277 98%);
    -webkit-box-shadow: 0px 0px 67px 13px rgba(64, 106, 189, 0.9);
    -moz-box-shadow: 0px 0px 67px 13px rgba(64, 106, 189, 0.9);
    box-shadow: 0px 0px 67px 13px rgba(64, 106, 189, 0.9);
    color: white;
    border-radius: 1rem;
    margin-bottom: 4rem;
}

.open-class-banner-cont.enrolled {
    background-image: linear-gradient(315deg, #198754 0%, rgb(89, 182, 139) 51%, #198754 98%);
    -webkit-box-shadow: 0px 0px 67px 13px rgba(25, 135, 84, 0.9);
    -moz-box-shadow: 0px 0px 67px 13px rgba(25, 135, 84, 0.9);
    box-shadow: 0px 0px 67px 13px rgba(25, 135, 84, 0.9);

}

.open-class-banner-cont .open-class-info h3 {
    color: white;
    font-size: 1.25rem;

}

.open-class-banner-cont .submit-btn {
    padding: 0.5rem 0;
    transition: all ease 0.3s;
}

.open-class-banner-cont .mini-loader-cont {
    width: 1.5rem;
    height: 1.5rem;
}


.open-class-banner-cont .mini-loader-cont .loader {
    width: 100%;
    height: 100%;
}

.open-class-banner-cont .open-class-title {
    color: white;
}

.open-class-banner-cont .submit-btn {
    width: 50%;
}

@media screen and (max-width: 768px) {
    .open-class-banner-cont {
        padding: 1rem;
    }

    .open-class-banner-cont .submit-btn {
        width: 100%;
    }
}

/* .open-class-banner-cont .open-class-description {
} */