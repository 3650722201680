.cont-home .welcome-cont {
    padding: 2rem;
    border: 2px solid var(--main-blue);
    border-radius: 1rem;
    margin-bottom: 4rem;
}

.cont-home .welcome-cont .title {
    color: var(--main-blue);
}

.cont-home .welcome-cont .slogan {
    font-size: 1.5rem;
    margin: 0;
}

.cont-home section:not(:first-child) {
    /* gap: 10rem; */
    margin-bottom: 4rem;
}

.cont-home h2:not(.open-class-title) {
    display: inline-block;
    margin-bottom: 2rem;
    padding-top: 1rem;
    border-top: 0.5rem solid var(--main-blue);
    color: var(--main-blue);
}

.cont-home img:not(.home-swiper-img) {
    /* max-width: 50%; */
    max-height: 550px;
    border-radius: 1rem;
    object-fit: cover;
}

.cont-home .home-swiper-img {
    border-radius: 1rem;
    object-fit: cover;
    max-width: 100%;
    /* min-width: 200px; */
    max-height: 600px;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cont-home .classes-row {
    flex-direction: row-reverse;
}

.cont-home .contact-row {
    flex-direction: row-reverse;
}

.cont-home iframe {
    width: 100%;
    height: 500px;
}

.cont-home .contact-row li>span.pi {
    color: var(--main-blue);
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .cont-home .map-cont {
        margin: auto;
        margin-top: 1.5rem;
        width: 95%;
    }
}