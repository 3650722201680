.shift-cont {
  /* overflow: hidden; */
  max-width: 100%;
  overflow: hidden;
}

.shift-cont ul {
  padding-left: 0;
  overflow: auto;
}

.shift-cont ul li {
  list-style-type: none;
}

.cont-list-days {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.cont-list-days li {
  border: 1px solid lightgray;
  border-radius: 0.5rem;
}

.modal-title {
  background-color: var(--main-blue);
  color: white;
}

.modal-title .btn-close {
  display: none;
}

.btn-close-link {
  color: var(--main-grey);
  text-decoration: none;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1.5em' height='1.5em' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z'/%3E%3C/svg%3E") no-repeat center center;

}

.btn-close-link:hover {
  color: gray;
}

.item-day {
  /* width: auto; */
  min-width: 150px;
  width: 100%;
  height: calc(70px * 15 + 45px);
  padding: 0.15rem;
}

.item-day h2 {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--main-blue);
}

.cont-shift-card {
  position: relative;
  display: block;
}

.btn-full {
  background: none;
  border: none;
}

.btn-full[disabled] {
  background: none;
  color: var(--main-grey);
  opacity: 100;
}

.btn-full::after {
  content: 'No hay cupos disponibles';
  transition: 0.15s ease-in-out all;
  background: none;
}

.btn-loading,
.btn-loading:hover,
.btn-loading:disabled {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  background: none;
  border: none;
  cursor: not-allowed;
  opacity: 100;
}

.btn-inscripto {
  background: none;
  border: none;
}

.btn-inscripto:hover {
  background: none;
  border: none;
}

/* .btn-inscripto::after {
  content: 'Ya estás inscripto';
  transition: 0.15s ease-in-out all;
  background: url(https://api.iconify.design/material-symbols:check-circle.svg?color=%23666666) no-repeat center left;
  padding-left: 1.25rem;
}

.btn-inscripto-loading::after {
  content: '';
  transition: 0.15s ease-in-out all;
  padding-left: 1.25rem;
}

.btn-inscripto:hover::after {
  content: 'Darse de baja';
  color: #dc3545;
  background: url(https://api.iconify.design/material-symbols:cancel.svg?color=%23dc3545) no-repeat center left;
} */