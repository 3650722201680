.card-view-profile-enrollments-container .enrollment-card {
    padding-block: 2rem;
    align-items: center;
    text-align: center;

}
.card-view-profile-enrollments-container .enrollment-card>div {
    padding-block: 2rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;

}
