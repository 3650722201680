.cont-admin-form-users form {
  display: flex;
  flex-direction: column;
  gap: 1rem
}

.cont-admin-form-users .avatar-img {
  object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: 100%;
  height: 150px;
}


@media (min-width: 992px) {
  .cont-admin-form-users .w-lg-50 {
    width: 50% !important;
  }
}